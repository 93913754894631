@keyframes breath {
    0% {
        box-shadow: 0 0 3px 0px rgba(248, 199, 57, 0.6);
    }

    25% {
        box-shadow: 0 0 5px 5px rgba(248, 199, 57, 0.6);
    }

    100% {
        box-shadow: 0 0 3px 0px rgba(248, 199, 57, 0.6);
    }
}

.countSteps {
    position: relative;
    width: 149px;

    .avatar {
        position: absolute;
        z-index: 1;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        padding: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 0 3px 0px rgba(248, 199, 57, 0.6);

        &.breathing {
            animation: breath infinite 2s forwards;
        }

        >img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: linear-gradient(180deg, #68C3E2 0%, #FFB0EE 100%);
        }
    }

    .content {
        height: 185px;
        margin-top: 19px;
        padding-bottom: 19px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(4px);
        color: #00395D;
        font-weight: 500;
        overflow: hidden;

        .nickname {
            margin: 8px 0 0 61px;
            font-size: 14px;
        }

        .steps, .ranking {
            margin: 8px 0 0 17px;

            .label {
                font-size: 12px;
            }

            .value {
                font-family: 'Cera Pro';
                font-weight: 900;
                font-size: 32px;
                line-height: 36px;
            }
        }
    }

    .expanse {
        position: absolute;
        width: 17px;
        height: 17px;
        bottom: 6px;
        right: 6px;

        >img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.dev {
    width: 90%;
    max-width: calc(100% - 54px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #FFFFFF;
}
