.ar-overlay {
    position: absolute;
    right: 0;
}

.grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    padding: 42px 0 24px 0;
    column-gap: 10px;
    row-gap: 10px;
    z-index: 10;
    width: 100%;
    position: fixed;
    transition: 0.5s;
    justify-content: center;
}

.emotes-btn {
    margin: 16px;
    background: rgba(255,255,255,0.3);
    min-width: 48px;
    min-height: 48px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}