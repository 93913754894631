.App {
    text-align: center;
    min-height: 60vh;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.side-panel {
    background-color: #353535;
    height: 100vh;
    width: 10%;
    min-width: 54px;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 16px 0;
}

.scroll-container {
    overflow-y: auto;
}

.scroll-container>div {
    border-radius: 8px;
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
}

.dev-panel {
    margin-top: auto;
    height: 50px;
    width: 50px;
    background-color: black;
    color: white;
    border-radius: 5px;
    text-align: center;
}

.main {
    overflow: hidden;
    width: 100%;
    height: 100%;
}