.bg {
    background: #0E195E;
    height: 100vh;
    overflow: hidden;
}

.circle {
    position: absolute;
    width: 50vw;
    height: 50vw;
    left: -50%;
    top: -50%;
    background: #FFFFFF;
    opacity: 0.2;
    filter: blur(150px);
    border-radius: 50%;
}

.logo {
    position: absolute;
    top: 30px;
    left: 20px;
    width: 40px;
    height: 40px;
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    padding-top: 30px;
}

.bricksWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.bricks {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@keyframes slideOutUp {
    from {
        transform: translate3d(0, 100%, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

.slideOutUp {
    animation-name: slideOutUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}
